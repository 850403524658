import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { BodySmall, TitleSecondary } from '@components/styled/Typography';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-solid/xmark-with-cross-circle-solid.svg';
import { ContainerColumn } from '@src/components/styled';
import DeleteAccountSidebar from '../../sidebars/DeleteAccount';
const DeleteIcon = styled(XMarkIcon)(props => ({
    width: 64,
    height: 64,
    minWidth: 64,
    circle: {
        fill: props.theme.palette.error.light,
    },
    path: {
        stroke: props.theme.palette.error.main,
        strokeWidth: 1,
    },
}));
const DeleteAccountContainer = styled.div(props => ({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'start',
    padding: `${props.theme.spacing_sizes.xs * 3.25}px ${props.theme.spacing_sizes.l}px`,
    gap: `${props.theme.spacing_sizes.xs * 2}px`,
    backgroundColor: props.theme.customColors.container.errorLight,
    border: `2px solid ${props.theme.customColors.container.errorLight}`,
    borderRadius: props.theme.spacing_sizes.xs * 1.25,
    cursor: 'pointer',
    flex: 1,
    '&:hover': {
        borderColor: '#F9C6C6',
    },
    '&:active': {
        borderColor: props.theme.palette.error.main,
        boxShadow: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
    },
}));
const AvatarContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 64,
    minWidth: 64,
    borderRadius: '50%',
    backgroundColor: props.theme.customColors.badge.trader.surface,
}));
const DeleteAccountTextContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const Title = styled(TitleSecondary)(props => ({
    lineHeight: `${props.theme.spacing_sizes.xs * 2.5}px`,
}));
const Description = styled(BodySmall)(props => ({
    fontSize: props.theme.spacing_sizes.xs * 1.75,
}));
const DeleteAccount = () => {
    const { t } = useTranslation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarOnClose = () => {
        setIsSidebarOpen(false);
    };
    const openSidebar = () => {
        setIsSidebarOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(DeleteAccountContainer, { onClick: openSidebar, children: [_jsx(AvatarContainer, { children: _jsx(DeleteIcon, {}) }), _jsxs(DeleteAccountTextContainer, { children: [_jsx(Title, { children: t('settings.tabs.personal_info.delete_account.title') }), _jsx(Description, { children: t('settings.tabs.personal_info.delete_account.text') })] })] }), _jsx(DeleteAccountSidebar, { isOpen: isSidebarOpen, onClose: sidebarOnClose, shouldCloseOnOutsideClick: true, destroyOnHide: true })] }));
};
export default DeleteAccount;
