import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useAppSelector } from '@app/store/Hooks';
import { IsDefined } from '@shared/lib/js-ts';
import CreateTraderButton from './CreateTraderButton';
import SwitchProfileBadgeButton from './SwitchProfileBadgeButton';
import DeleteAccount from './DeleteAccount';
const Container = styled.div(props => ({
    display: 'flex',
    gap: `${props.theme.spacing_sizes.m}px`,
}));
const ProfileManagement = () => {
    const { user } = useAppSelector(state => state.user);
    const clientProfile = user === null || user === void 0 ? void 0 : user.clientProfile;
    const traderProfile = user === null || user === void 0 ? void 0 : user.traderProfile;
    return (_jsxs(Container, { children: [IsDefined(clientProfile) && (_jsx(SwitchProfileBadgeButton, { profile: clientProfile }, clientProfile.id)), IsDefined(traderProfile) && (_jsx(SwitchProfileBadgeButton, { profile: traderProfile }, traderProfile.id)), !IsDefined(traderProfile) && (_jsx(CreateTraderButton, {})), _jsx(DeleteAccount, {})] }));
};
export default ProfileManagement;
